import React, { useState, useEffect  } from 'react';

import { lazy, Suspense } from 'react';
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";

import './App.css';
import './aide.css';
import './index.css';

//antd imports
import { Breadcrumb, Menu, theme,Input, Button, Switch, Slider, Select, DatePicker, InputNumber } from 'antd';
import { Layout, Grid, Col, Row, Space,Divider, Flex, Radio } from 'antd';
import {  Dropdown, message  } from 'antd';
//import {  DownOutlined  } from 'antd/icons';

//theme config
import { ConfigProvider } from 'antd';


//translation lib
import { useTranslation } from 'react-i18next';


//aide cms
import AideEduBaseContent from './containers/docu/aide_edubase_content';

//aide menu's
import AideDocsMenu from './menus/aide_docs_menu';
import { isArray, isNil } from '@ant-design/plots/es/core/utils';

import Global from './global/global'
import User from './global/user'


//lazy imports of aide containers
const Account = lazy(() => import ('./containers/account/account'));
const AccountProfile = lazy(() => import ('./containers/account/account_profile'));
const AccountRegister = lazy(() => import ('./containers/account/account_register'));
const MyLibrary = lazy(() => import ('./containers/account/my_library'));
const EnvironmentEditor = lazy(() => import ('./containers/environment/environment_editor'));
const SimulEditor = lazy(() => import ('./containers/simulation/simulation_editor'));
const CoinRate = lazy(() => import ('./containers/coin/coin_rate'));
const CoinWallet = lazy(() => import ('./containers/coin/coin_wallet'));
const CoinPayout = lazy(() => import ('./containers/coin/coin_payout'));
const MiningStats = lazy(() => import ('./containers/miner/miners'));
const LiveBranchTree = lazy(() => import ('./containers/brancher/live_branch'));
const Miners = lazy(() => import ('./containers/miner/miners'));
const Branchers = lazy(() => import ('./containers/brancher/branchers'));
const BrancherCode = lazy(() => import ('./containers/brancher/brancher_code'));
const BrancherCodes = lazy(() => import ('./containers/brancher/brancher_codes'));
const MinerTasks = lazy(() => import ('./containers/miner/miner_tasks'));
const MinerTask = lazy(() => import ('./containers/miner/miner_task'));
const CreatureView = lazy(() => import ('./containers/creature/creature_view'));
const CreatureEditor = lazy(() => import ('./containers/creature/creature_editor'));
const CreatureUpload = lazy(() => import ('./containers/creature/creature_upload'));
const LiveSimul2d = lazy(() => import ('./containers/simulation/live_simul2d'));
const LiveSimul3d = lazy(() => import ('./containers/simulation/live_simul3d'));

const { Header, Footer, Content } = Layout;

const { darkAlgorithm, defaultAlgorithm, useToken } = theme;

const App: React.FC = (props) => {

  const [dyn_menu_items, setDynMenuItems] = useState([]);
  
  const [current_theme, setTheme]  = useState("light");
  const [dark_mode, setDarkmode]  = useState(false);  
  
  //translation function
  const { t, i18next } = useTranslation();    

  const navigate = useNavigate();
  const location = useLocation();  

  useEffect(() => {
      setTheme(Global.current_theme);
  }, [Global.current_theme]);
  useEffect(() => {
    setDarkmode(Global.darkmode);
  }, [Global.darkmode]);

  useEffect(() => {
      const domain_root = process.env.REACT_APP_HTTPS_DB_DOMAIN_ROOT;
      const url_menu = `${domain_root}/index.php/user/menu?lang=NL`;
      const url_trainings = `${domain_root}/index.php/db/getTrainings?type=public&lang=NL`;
      const url_training_programs = `${domain_root}/index.php/db/getTrainingPrograms?type=public&lang=NL`;
      const db_menu_items = [];

      const findArrayElementById = (array, id)  => {
        let result = null;
        array.forEach((el) => {
          if(el.id === id)
            result= el;
    
          //look into the children
          if(isArray(el.children))
            {
              let tmp = findArrayElementById(el.children,id);
              if(!isNil(tmp))
                result=tmp;
            }
        });
        return result;
      }
      const handleFetchMenu = () => {
        return fetch(url_menu,{method: 'GET',credentials: 'include'})
          .then(response => response.json())
       }; 
    
      const handleFetchTrainingPrograms = () => {
        return fetch(url_training_programs,{method: 'GET',credentials: 'include'})
          .then(response => response.json())
       }; 
    
      const handleFetchTrainings = () => {
        return fetch(url_trainings,{method: 'GET',credentials: 'include'})
          .then(response => response.json())
       };    

      handleFetchMenu()
        .then((response) => {
            response.map((element, idx) => {
              let child = [];
              child['key'] = element.link;
              child['id'] = element.id;
              if(isNil(element.title))
                child['label'] = element.name;
              else
                child['label'] = element.title;
              let parent = findArrayElementById(db_menu_items,element.parent_id);
              if(isNil(parent))
              {
                db_menu_items.push(child);
              }
              else
              {
                if(!isArray(parent.children))
                  parent.children = [];
                parent.children.push(child);
              }
              return db_menu_items;
            });
            //setDynMenuItems(db_menu_items);
            
            })
        .then(handleFetchTrainingPrograms)
        .then(response => {
          response.map((element, idx) => {
            let child = [];
            child['key'] = element.menu_id;
            child['id'] = element.menu_id;
            child['label'] = element.title;
            let parent = findArrayElementById(db_menu_items,element.parent_menu_id);
            if(!isNil(parent))
            {
              if(!isArray(parent.children))
                parent.children = [];
              parent.children.push(child);
            }
            return db_menu_items;
          })
          })
        .then(handleFetchTrainings)
        .then(response => {
          response.map((element, idx) => {
            let child = [];
            child['key'] = element.link;
            child['id'] = (element.parent_menu_id * 10) + element.id;
            child['label'] = element.title;
            let parent = findArrayElementById(db_menu_items,element.parent_menu_id);
            if(!isNil(parent))
            {
              if(!isArray(parent.children))
                parent.children = [];
              parent.children.push(child);
            }            
            setDynMenuItems(db_menu_items);
            return db_menu_items;
          })
        })
                               
    }, [useLocation().pathname]);  


    const onClick = ({ key }) => {
      message.info(`Click on item ${key}`);
    };
    
  const ToggleDarkMode = () =>{
      if(Global.darkmode)
      {
          Global.darkmode = false;
          Global.current_theme = 'light';
      }
      else
      {
          Global.darkmode = true;
          Global.current_theme = 'dark';
      }
      setDarkmode(Global.darkmode);
      navigate(location.pathname);
  }

  return (
  <ConfigProvider theme={{ 
                  algorithm: dark_mode ? theme.darkAlgorithm : theme.defaultAlgorithm,
                  }}
                  
                  >        
    <Layout >
        <Header className="aide_main_div" style={{ colorScheme: current_theme }}> 
          {/* class="aide_main_div" style={{ colorScheme: current_theme }}  */}
          <Row>
            <Col span={3}>
              <div className="logo" >
              <img src="/neural.png" alt="neural net" style={{width:100,height:65}}/>
              </div>
            </Col>
            <Col span={18}>
            <Menu  mode="horizontal" defaultSelectedKeys={['1']} items={dyn_menu_items} 
              onClick={({ keyPath }) => navigate(`/${keyPath[0]}`)}/>
            </Col>
            <Col span={1}>
            <Switch  checkedChildren={t('dark')} 
                            unCheckedChildren={t('light')} 
                            value = {dark_mode}
                            onChange={(e) => {ToggleDarkMode()}}/>
            </Col>
          </Row>
        </Header>

          
        {/*Full renderers */}
        <Suspense fallback={<div className="container">Loading...</div>}>
          <Routes >
            <Route path="aide-designer/*" element={<CreatureEditor creature_name={useLocation().pathname.split('/')[2]} />} />
            <Route path="env-designer/*" element={<EnvironmentEditor environment_uid={useLocation().pathname.split('/')[2]} />} />
            <Route path="simul-designer/*" element={<SimulEditor simulation_uid={useLocation().pathname.split('/')[2]} />} />
            <Route path="account-profile/*" element={<AccountProfile/>} />
            <Route path="*" element={<div/>} />
          </Routes>
        </Suspense>

        <Layout style={{ padding: '5px 5px 5px' }} >
        
          {/* Menu renderers */}
          <Suspense fallback={<div className="container">Loading...</div>}>
            <Routes >
                    <Route path="aide-docs/*" element={<AideDocsMenu  dark_mode={dark_mode}/>} />
                    {/* <Route path="aide-designer/*" element={<AideDesignerMenu/>} /> */}
                    <Route path="*" element={<div/>} />
            </Routes>             
          </Suspense>

            {/* Content renderers */} 
              <Content className="aide_main_div" 
                  style={{
                    padding: 24,
                    margin: 0,
                    minHeight: 800,
                    colorScheme: current_theme
                }}
              >

              <Suspense fallback={<div className="container">Loading...</div>}>
                  <Routes >
                      <Route path="" element={<AideEduBaseContent type='aide-webpages' slug='company-info'/>}/>
                      <Route path="aide-designer/*" element={<div/>}/>
                      <Route path="env-designer/*" element={<div/>} />

                      <Route path="account-login" element={<Account action='login'/>} />
                      <Route path="account-login/logout" element={<Account action='logout'/>} />
                      <Route path="account-profile/*" element={<div/>} />
                      <Route path="account-register" element={<AccountRegister/>} />
                      <Route path="lang/*" element={<Account action={'lang'} parameter={useLocation().pathname.split('/')[2]} prev_path={'/'} />}/>

                      <Route path="coin-payout" element={<CoinPayout/>} />
                      <Route path="coin-rate" element={<CoinRate/>} />
                      <Route path="coin-wallet" element={<CoinWallet/>} />                    
                      <Route path="mining-stats" element={<MiningStats/>} />
                      <Route path="live-simul3d/*" element={<LiveSimul3d x='1380' y='800'/>} />
                      <Route path="live-simul2d/*" element={<LiveSimul2d x='1380' y='600'/>} />
                      <Route path="live-branchtree" element={<LiveBranchTree/>} />
                      
                      <Route path="miners" element={<Miners/>} />
                      <Route path="brancher-info" element={<AideEduBaseContent type='aide-webpages' slug='brancher-info'/>}/>
                      <Route path="branchers" element={<Branchers/>} />
                      <Route path="brancher-codes" element={<BrancherCodes/>} />
                      <Route path="brancher-codes/*" element={<BrancherCode code_id_to_edit={useLocation().pathname.split('/')[2]}/>} />

                      <Route path="miner-info" element={<AideEduBaseContent type='aide-webpages' slug='miner-info'/>}/>
                      <Route path="miner-tasks" element={<MinerTasks/>} />
                      <Route path="miner-tasks/*" element={<MinerTask task_to_show={useLocation().pathname.split('/')[2]}/>} />

                      <Route path="creature-view" element={<CreatureView/>} />
                      <Route path="my-library/*" element={<MyLibrary submenu_action={useLocation().pathname.split('/')[2]}/>} />
                      <Route path="creature-upload" element={<CreatureUpload/>} />

                      {/* <Route path="mystory_for_ewor" element={<AideStrapiContent type='aide-webpages' slug='storyforewor'/> } /> */}
                      <Route path="aide-docs" element={<AideEduBaseContent type='aide-webpages' slug='aide-docs'/> } />
                      <Route path="aide-docs/*" element={<AideEduBaseContent type='aide-docs' slug={useLocation().pathname.split('/')[2]}/> } />
                      <Route path="w/*" element={<AideEduBaseContent type='aide-webpages' slug={useLocation().pathname.split('/')[2]}/> } />
                      {/* <Route path="w/*" element={<AideStrapiContent type='aide-webpages' slug={useLocation().pathname.split('/')[2]}/> } /> */}
                      {/* <Route path="*" element={<AideStrapiContent type='aide-webpages' slug={useLocation().pathname.replace(/^\/+/, '')}/> } /> */}

                      
                  </Routes>
                </Suspense>

              </Content>
        </Layout>
      
      <Footer>Copyrights Aidetool 2024</Footer>
    </Layout>
    </ConfigProvider>
  );
};



export default App;
